import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import i18n from "../../../../../i18n";
import { apiManager } from "../../../../../api/connectAPI";
import { typeCoverPicture } from "../../../../../utils/types";
import { useDispatch } from "react-redux";
import { myprofilefetch } from "../../../../../Reducer/myprofileSlice";
import ViewImageModal from "../../../../Tools/ViewImageModal/view-image-modal";
export default function UploadCover({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Basicetails = data.Basicetails;
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  var length = Object.keys(data).length;
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file || !fileTypes.includes(file.type)) {
      setFileError("File upload with valid file type");
      return;
    }
    setFileError("");
    const formData = new FormData();
    formData.append("files", file);
    setLoading(true);
    apiManager(
      "POST",
      `storage/upload?lang=${lang}&type=${typeCoverPicture}`,
      formData,
      null,
      "multipart/form-data"
    )
      .then((res) => {
        if (res.status) {
          dispatch(myprofilefetch());
        } else {
          console.log(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [ppModal, setPpModal] = useState(false);
  const [ppModalData, setPpModalData] = useState(null);
  const handleClosePpModal = () => {
    setPpModal(false);
  };
  const openPpModal = (data) => {
    setPpModalData(data);
    setPpModal(true);
  };

  return (
    <div>
      {length > 0 ? (
        <>
          <h3 class="securityh3">
            {t("Cover image")}
            {data?.coverPicture && data?.coverPicture !== "N/A" && (
              <label
                htmlFor="coverFileEdt"
                class="edt"
                style={{ cursor: "pointer" }}
              >
                <img src="../assets/images/pencil.png" alt="pencil" />{" "}
                {t("Edit cover picture")}
                <input
                  id="coverFileEdt"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleChange}
                  accept={fileTypes.join(", ")}
                />
              </label>
            )}
          </h3>
          {loading ? (
            <div
              class="d-flex justify-content-center"
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div class="row">
              {data?.coverPicture && data?.coverPicture !== "N/A" ? (
                <div
                  class="col-sm-12 d-flex justify-content-center"
                  role="button"
                  onClick={() => openPpModal({ image: data?.coverPicture })}
                >
                  <img
                    src={data?.coverPicture}
                    height={150}
                    alt=""
                    className="img-fluid rounded-2 shadow-sm w-100 "
                    style={{ maxHeight: "300px", objectFit: "cover" }}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center mb-4">
                  <div className="row p-3">
                    <div className="col-lg-12 text-center">
                      {t("Upload cover picture")}
                    </div>
                    <div className="col-lg-12">
                      <label
                        htmlFor="coverFile"
                        style={{ cursor: "pointer" }}
                        className="d-flex justify-content-center"
                      >
                        <img
                          src="../assets/images/pencil-alt.webp"
                          height={25}
                          alt="pencil"
                        />
                      </label>
                      <input
                        id="coverFile"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <p className="helperText fs-6 text-danger mt-3">{t(fileError)}</p>
      <ViewImageModal
        modal={ppModal}
        handleClose={handleClosePpModal}
        modalData={ppModalData}
      />
    </div>
  );
}
