import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDefaultSearchRental } from './api';

export const fetchDefaultDRFilterData = createAsyncThunk('searching/dealer_rental', async (formData, { rejectWithValue }) => {
  try {
    const searched = await fetchDefaultSearchRental(formData);
    console.log('searched', searched)
    if(formData.page === 'normal') {
      return {cars: {data: searched?.data, totalPage: searched?.totalPage}, sideBar: searched?.sideBar}
    } 
    if(formData.page === 'rental') {
      return {rental: {data: searched?.data, totalPage: searched?.totalPage}, sideBar: searched?.sideBar}
    }
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const dealerRentalSlice = createSlice({
  name: 'dealer_rental',
  initialState: {
    cars: {},
    sideBar:{},
    rental:{},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDefaultDRFilterData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDefaultDRFilterData.fulfilled, (state, action) => {
        state.sideBar = action.payload.sideBar ? action.payload.sideBar :[];
        state.cars=action.payload.cars ? action.payload.cars :{};
        state.rental=action.payload.rental ? action.payload.rental :{};
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchDefaultDRFilterData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default dealerRentalSlice.reducer;
