import React, { useEffect, useState } from "react";
import Crousel from "../../Tools/Crousel";
import { useTranslation } from "react-i18next";
import ListItem from "../../Tools/ListItem";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtherCar from "./otherCar";
import CarDetailModel from "../../Tools/CarDetailModel";
import {
  fetchFilteredSearchResults,
  fetchSingleCar,
  initiateChat,
  likeDislikePost,
  userActivity,
} from "../../../Reducer/api";
import { fetchSaved } from "../../../Reducer/savedSlice";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  getNameFromVal,
  isPositiveWholeNumber,
  itemLimit,
  numberWithCommas,
  userActivityLocal,
} from "../../../utils/constants";
import { Button, Modal, ModalBody } from "reactstrap";
import EnquiryForm from "../Enquiry/EnquiryForm";
import SinglePageRight from "./singlePageRight";
import { apiManager } from "../../../api/connectAPI";
import moment from "moment";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react/dist/iconify.js";
import { paths } from "../../../utils/paths";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import adFormData from "../../../data/withoutVin.json";
import CompareComponent from "./compareComponent";

export default function SingleCar({ page }) {
  // let data = useSelector((state) => state.singleCar.detail);
  // const loading = useSelector((state) => state.singleCar.loading);
  // const data?.data?.[0] = data?.data?.[0];
  const { t, i18n } = useTranslation();
  const udata = useSelector((state) => state.auth);
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const location = useLocation();
  const adsArray = location?.state?.adsArray || [];
  const posInArray = location?.state?.pos;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [pageloading, setPageLoading] = useState(true);
  const error = useSelector((state) => state.singleCar.error);
  const [params] = useSearchParams();
  const adId = params.get("adId");
  const sold = params.get("sold");

  useEffect(() => {
    const adIdToChat = localStorage.getItem("adIdToChat");
    if (adIdToChat) {
      const tmp = localStorage.getItem("chatInitiated");
      if (tmp == "true") {
        localStorage.setItem("chatInitiated", "false");
        localStorage.setItem("adIdToChat", "");
        handleInitiateChat(adIdToChat);
      }
    }
  }, [localStorage]);

  useEffect(() => {
    setPageLoading(true);
    // userActivityLocal(adId)
    // dispatch(fetchCarDetails(form));
    userActivity({ adId: adId, type: "view" });

    if (localStorage.getItem("token")) {
      dispatch(fetchSaved());
    }

    window.scrollTo(0,0)

    let form = { id: adId, page: page, sold: sold };
    fetchSingleCar(form).then((res) => {
      console.log(res);
      setData(res);
      const ad = res?.data?.[0];
      const title =
        t(ad?.properties?.basicDetails?.make?.[lang]) +
        " " +
        t(ad?.properties?.basicDetails?.model?.[lang]);
      document.title = title;
      setPageLoading(false);
    });
  }, [dispatch, params.get("adId")]);
  const savedCars = useSelector((state) => state.saved.saved)?.ads?.map(
    (v) => v._id
  );

  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const navigate = useNavigate();
  const [day, setDay] = useState(page);
  const [dealerCars, setDealerCars] = useState([]);

  const handleChange = (e) => {
    setRadio(e.target.value);
    setDay(e.target.value);
  };

  const [all_images, set_all_images] = useState([]);
  useEffect(() => {
    if (data?.data?.[0]) {
      const ad = data?.data?.[0];
      let all_list = findUploads(ad);
      set_all_images(all_list);
    }
  }, [data?.data?.[0]]);

  const getDealerCars = () => {
    setDealerCars(null);
    fetchFilteredSearchResults({
      page: page,
      limit: itemLimit,
      currentPage: 0,
      brandId: null,
      ownerId: data?.data?.[0]?.owner?._id,
    }).then((res) => {
      const withoutCurrent = res?.data?.filter((v) => v._id !== adId);
      setDealerCars({
        data: withoutCurrent,
        adsArray: res?.allAdIds
      });
    });
  };

  useEffect(() => {
    if (data?.data?.[0]?.owner?._id) {
      getDealerCars();
    }
  }, [params.get("adId"), data]);

  const getPrice = () => {
    switch (day) {
      case "normal":
        return `${formatPrice(
          lang,
          data?.data?.[0]?.askPrice,
          data?.data?.[0]?.dollorAskPrice
        )}`;
      case "daily":
        return `${formatPrice(
          lang,
          data?.data?.[0]?.askPrice,
          data?.data?.[0]?.dollorAskPrice
        )}/${t("Daily")}`;
      case "weekly":
        return `${formatPrice(
          lang,
          data?.data?.[0]?.askPrice1,
          data?.data?.[0]?.dollorAskPrice1
        )}/${t("Weekly")}`;
      case "monthly":
        return `${formatPrice(
          lang,
          data?.data?.[0]?.askPrice2,
          data?.data?.[0]?.dollorAskPrice2
        )}/${t("Monthly")}`;
      case "rental":
        return `${formatPrice(
          lang,
          data?.data?.[0]?.askPrice,
          data?.data?.[0]?.dollorAskPrice
        )}/${t("Daily")}`;
    }
  };

  const [radioState, setRadio] = useState("daily");

  const [readMore, setReadMore] = useState(false);

  const [enquireModal, setEnquireModal] = useState(false);
  const handleCloseEnquireModal = () => {
    setEnquireModal(false);
  };

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const [chatNowPop, setChatNowPop] = useState(false);
  const handleInitiateChat = (id) => {
    if (chatNow.loading) return;
    if (!udata.token) {
      navigate(paths.login, {
        state: {
          backPage: location.pathname + location.search,
          request: "chat",
          adIdToChat: id,
        },
      });
      return;
    }

    if (!profileData?.isProfileCompleted || !profileData?.phoneNumberVerified) {
      setChatNowPop(true);
      setTimeout(() => {
        setChatNowPop(false);
      }, 2000);
      return;
    }

    setChatNow({ ...chatNow, adId: id, loading: true });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate(`${paths.messages}?id=${res.chatId}`);
        }
        setChatNow({ ...chatNow, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStartEnquiry = () => {
    if (!udata.token) {
      setEnquireModal(true);
      return;
    }
    handleEnquireLoggedIn();
  };
  const [enquireLoading, setEnquireLoading] = useState(false);

  const handleEnquireLoggedIn = () => {
    setEnquireLoading(true);
    const formData = {
      adId: adId,
      messageMetadata: {
        type: "enquiry",
        enquiryType: "test drive",
        enquiryDate: moment(new Date()).format("YYYY-MM-DD"),
      },
      enquiry: false,
      fullName: udata?.auth?.fullName,
      email: udata?.auth?.email,
      mobile: udata?.auth?.phoneNumber,
      message: "",
    };
    // console.log(formData);

    apiManager("POST", `chat/init?lang=${lang}`, formData)
      .then((res) => {
        if (res.status) {
          navigate(paths.messages, {
            state: { chatId: res.chatId },
          });
        } else {
          console.log(res);
          toast.error(res.message);
        }
        setEnquireLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setEnquireLoading(false);
      });
  };

  const displayData = {
    size:
      data?.data?.[0]?.properties?.specifications?.engineSize ||
      data?.data?.[0]?.properties?.specifications?.batterySize ||
      "",
    sizeUnit: data?.data?.[0]?.properties?.specifications?.engineSize
      ? t("Litre")
      : t("kWh"),
    fuel: data?.data?.[0]?.properties?.specifications?.fuelType?.[lang] || "",
    bodyType:
      data?.data?.[0]?.properties?.specifications?.bodyType?.[lang] || "",
    km:
      data?.data?.[0]?.properties?.specifications?.km ||
      data?.data?.[0]?.properties?.specifications?.km == 0
        ? data?.data?.[0]?.properties?.specifications?.km
        : "",
    gearbox: data?.data?.[0]?.properties?.specifications?.gearbox?.[lang] || "",
    doors: data?.data?.[0]?.properties?.specifications?.doors,
    seats: data?.data?.[0]?.properties?.specifications?.seats,
  };

  const moveInArray = (dir, arr, i) => {
    const goToAd = (id, newPos) => {
      const currentParams = new URLSearchParams(location.search);
      currentParams.set("adId", id);
      navigate(`${location.pathname}?${currentParams.toString()}`, {
        state: { adsArray: arr, pos: newPos },
        replace: true,
      });
    };
    if (dir === "next") {
      if (i !== arr.length - 1) {
        goToAd(arr[i + 1], i + 1);
      }
    } else {
      if (i !== 0) {
        goToAd(arr[i - 1], i - 1);
      }
    }
  };

  return (
    <>
      {data?.data?.[0] && Object.keys(data?.data?.[0]).length > 0 ? (
        <section class="singlepage">
          <div class="container">
            <h6 class="back mb-3">
              <a onClick={() => navigate(-1)}>
                <img src="../assets/images/backbt.png" /> {t("Go back")}
              </a>
            </h6>
            {adsArray?.length > 0 && (
              <h6 className="d-flex justify-content-between mb-3">
                <div
                  onClick={(e) => {
                    moveInArray("prev", adsArray, posInArray);
                  }}
                  role="button"
                  className="text-light rounded-pill py-1 px-3 d-flex justify-content-between align-items-center gap-2"
                  style={{
                    fontSize: "14px",
                    backgroundColor: posInArray === 0 ? "lightgrey" : "#4c7ee8",
                  }}
                >
                  <Icon
                    icon={`heroicons:arrow-${
                      lang === "en" ? "left" : "right"
                    }-20-solid`}
                    style={{ color: "white" }}
                  />
                  <span>Prev Ad</span>
                </div>
                <div
                  onClick={(e) => {
                    moveInArray("next", adsArray, posInArray);
                  }}
                  role="button"
                  className="text-light rounded-pill py-1 px-3 d-flex justify-content-between align-items-center gap-2"
                  style={{
                    fontSize: "14px",
                    backgroundColor:
                      posInArray === adsArray?.length - 1
                        ? "lightgrey"
                        : "#4c7ee8",
                  }}
                >
                  <span>Next Ad</span>
                  <Icon
                    icon={`heroicons:arrow-${
                      lang !== "en" ? "left" : "right"
                    }-20-solid`}
                    style={{ color: "white" }}
                  />
                </div>
              </h6>
            )}
            {pageloading ? (
              <div
                class="d-flex justify-content-center"
                style={{ minHeight: "100vh", marginTop: "25vh" }}
              >
                <div class="spinner-grow" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-lg-6 col-xxl-7">
                  <div class="singlepage_left" style={{ position: "relative" }}>
                    <p
                      className="owl-item-badge"
                      style={{
                        zIndex: 9,
                        top: "0px",
                        [lang === "en" ? "left" : "right"]: "0px",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          borderRadius:
                            lang !== "en"
                              ? "0px 8px 0px 8px"
                              : "8px 0px 8px 0px",
                          fontSize: "11px",
                          backgroundColor: "#334660",
                        }}
                        class=" text-light float-start px-4"
                      >
                        {data?.data?.[0]?.owner?.userType === "user"
                          ? t("Private ad")
                          : ""}
                        {data?.data?.[0]?.owner?.userType === "brand" &&
                        data?.data?.[0]?.properties?.adLabel
                          ? t(data?.data?.[0]?.properties?.adLabel)
                          : ""}
                      </span>
                    </p>
                    <Crousel data={all_images ? all_images : []} />
                    <SinglePageRight
                      classNames={"singlepage_right d-lg-none d-block"}
                      page={page}
                      data={data}
                      radioState={radioState}
                      handleChange={handleChange}
                      getPrice={getPrice}
                      lang={lang}
                      params={params}
                      handleInitiateChat={handleInitiateChat}
                      chatNow={chatNow}
                      setChatNow={setChatNow}
                      chatNowPop={chatNowPop}
                    />

                    <h5
                      style={{ position: "unset" }}
                      className="d-flex justify-content-between align-items-center"
                    >
                      {t("Car Details")}
                      <CompareComponent
                        data={data?.data?.[0]}
                        className={"d-none d-lg-flex"}
                      />
                    </h5>
                    {displayData?.km && (
                      <h6>
                        <img src="../assets/images/adt.png" align="icon" />{" "}
                        {`${numberWithCommas(displayData?.km)} ${t("KMs")}`}
                      </h6>
                    )}
                    <ul class="hatchback">
                      {displayData?.bodyType && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil1.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.bodyType)}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.fuel && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil5.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.fuel)}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.size && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil2.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {displayData?.size +
                                  " " +
                                  displayData?.sizeUnit}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.gearbox && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil3.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.gearbox)}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.doors && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil4.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.doors)} {t("Doors")}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.seats && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil6.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.seats)} {t("Seats")}
                              </span>
                            </>
                          }
                        />
                      )}
                    </ul>
                    {data?.data?.[0]?.properties?.features?.carDescription?.[
                      lang
                    ]?.charAt(0) !== "["}
                    {data?.data?.[0]?.properties?.features?.carDescription?.[
                      lang
                    ] &&
                    data?.data?.[0]?.properties?.features?.carDescription?.[
                      lang
                    ]?.charAt(0) !== "[" ? (
                      <>
                        <h5 class="mb-3">{t("Description")}</h5>
                        <p
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: readMore ? 100 : 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            // idth: "350px",
                          }}
                        >
                          {data?.data?.[0]?.properties?.features
                            ?.carDescription?.[lang]
                            ? data?.data?.[0]?.properties?.features
                                ?.carDescription?.[lang]
                            : "--"}
                        </p>
                        {data?.data?.[0]?.properties?.features
                          ?.carDescription?.[lang]?.length > 190 && (
                          <a
                            className="mb-3"
                            onClick={() => {
                              setReadMore(!readMore);
                            }}
                          >
                            {readMore ? t("See less") : t("See more")}
                          </a>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <h5
                      class="spe"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {t("Specifications")}{" "}
                      <img src="../assets/images/aron.png" align="aro" />
                    </h5>
                  </div>
                </div>
                <div class="col-lg-6 col-xxl-5">
                  <SinglePageRight
                    classNames={"singlepage_right d-lg-block d-none"}
                    page={page}
                    data={data}
                    radioState={radioState}
                    handleChange={handleChange}
                    getPrice={getPrice}
                    lang={lang}
                    params={params}
                    handleInitiateChat={handleInitiateChat}
                    chatNow={chatNow}
                    setChatNow={setChatNow}
                    chatNowPop={chatNowPop}
                  />

                  {data?.data?.[0]?.owner?._id !== udata.auth.id && (
                    <div
                      className="singlepage_right"
                      style={{ marginTop: "-20px" }}
                    >
                      <div class="inset">
                        <h5>
                          {t(
                            `Want to ${
                              page == "normal" ? "buy" : "rent"
                            } this car?`
                          )}
                        </h5>
                        <p>
                          {t("Total Price")} <span>{getPrice()}</span>
                        </p>
                        <button
                          style={{ border: "none" }}
                          disabled={enquireLoading}
                          class="Enquire_Now"
                          onClick={() => handleStartEnquiry()}
                        >
                          {enquireLoading
                            ? t("Please wait...")
                            : t("Enquire Now")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <div
          class="d-flex justify-content-center"
          style={{ minHeight: "100vh", marginTop: "25vh" }}
        >
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {data?.data?.[0]?.properties && (
        <CarDetailModel
          data={data?.data?.[0]?.properties ? data?.data?.[0]?.properties : []}
          title={t("Specifications")}
        />
      )}
      {dealerCars?.data?.length > 0 ? (
        <OtherCar page={page} dealerCars={dealerCars} carData={data?.data?.[0]} />
      ) : null}
      <Modal
        isOpen={enquireModal}
        toggle={handleCloseEnquireModal}
        size="lg"
        centered
      >
        <ModalBody>
          <a
            className="float-end p-2"
            onClick={() => handleCloseEnquireModal()}
          >
            <img src="../assets/images/close_img.png" alt="icon" height={22} />
          </a>
          <div>
            <section class="login enquiry" style={{ padding: "unset" }}>
              <div class="container">
                <EnquiryForm
                  adData={data?.data?.[0]}
                  adId={adId}
                  handleCloseEnquireModal={handleCloseEnquireModal}
                />
              </div>
            </section>
          </div>
        </ModalBody>
      </Modal>

      <JsonLDSchemaHandler
        data={page == "normal" ? ldSchemas?.singleCar : ldSchemas?.singleRental}
      />
    </>
  );
}
