import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { likeDislikePost } from "../../../Reducer/api";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSaved } from "../../../Reducer/savedSlice";
import { useTranslation } from "react-i18next";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  isImageOrVideo,
  makeTagText,
} from "../../../utils/constants";
import { Spinner } from "reactstrap";
import { paths } from "../../../utils/paths";

const OwlItemCars = ({
  responce,
  page,
  handleClick,
  onChange,
  selectedList,
  fill,
  unfill,
  iconText,
  checkBoxText,
  cars,
  rentType,
  adsArray
}) => {
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.auth.language);
  const savedLoading = useSelector((state) => state.saved.loading);
  const { t } = useTranslation();
  const [responces, setResponces] = useState(responce);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const saved = useSelector((state) => state.saved.saved);
  const [listOfSaved, setListOfSaved] = useState([]);
  const [beingSaved, setBeingSavedId] = useState("");
  useEffect(() => {
    let newList = saved?.ads?.map((v) => v._id);
    setListOfSaved(newList);
  }, [saved]);

  useEffect(() => {
    if (token) {
      dispatch(fetchSaved());
    }
  }, []);

  const toggleSaveCar = async (adId) => {
    setBeingSavedId(adId);

    if (!localStorage.getItem("token")) {
      navigate(paths.login, {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }

    const perform = listOfSaved?.includes(adId) ? "remove" : "add";
    const res = await likeDislikePost(adId, perform);
    dispatch(fetchSaved());
  };
  const getPrice = (item) => {
    let day = item?.adTypeName?.["en"] === "Sell" ? "normal" : rentType;
    switch (day) {
      case "normal":
        return `${formatPrice(lang, item?.askPrice, item?.dollorAskPrice)}`;
      case "daily":
        return `${formatPrice(lang, item?.askPrice, item?.dollorAskPrice)}/${t(
          "Daily"
        )}`;
      case "weekly":
        return `${formatPrice(
          lang,
          item?.askPrice1,
          item?.dollorAskPrice1
        )}/${t("Weekly")}`;
      case "monthly":
        return `${formatPrice(
          lang,
          item?.askPrice2,
          item?.dollorAskPrice2
        )}/${t("Monthly")}`;
      case "rental":
        return `${formatPrice(lang, item?.askPrice, item?.dollorAskPrice)}/${t(
          "Daily"
        )}`;
    }
  };

  const gotoSingleCar = (item, key) => {
    if (item?.adTypeName?.["en"] === "Sell") {
      navigate(`${paths.singleCar}?adId=${item?._id}`, {
        state: {
          // adsArray: [
          //   "67333369e8faf1581d1fcc97",
          //   "6728c5d1dcb3fde9d81e87ea",
          //   "672217d60a12df646e3c9d65",
          //   "67126014eb6e112057bc0708",
          // ],
          adsArray: adsArray,
          pos: key,
        },
      });
    } else {
      navigate(`${paths.singleRental}?adId=${item?._id}`);
    }
  };

  return (
    <>
      {cars &&
        cars?.map((item, key) => {
          const tagText = makeTagText(item, lang);
          let firstImage =
            findUploads(item)?.[0]?.thumbnail || item?.uploads?.[0]?.s3URL;
          const type = isImageOrVideo(firstImage);
          return (
            <div key={item?._id} class="item position-relative">
              <div class="set  overflow-hidden">
                <p className="owl-item-badge">
                  {" "}
                  <span
                    style={{
                      borderRadius:
                        lang !== "en" ? "0px 5px 0px 5px" : "5px 0px 5px 0px",
                      fontSize: "11px",
                      backgroundColor: "#334660",
                    }}
                    class=" text-light float-start px-4"
                  >
                    {item?.owner?.userType === "user" ? t("Private ad") : ""}
                    {item?.owner?.userType === "brand" &&
                    item?.properties?.adLabel
                      ? t(item?.properties?.adLabel)
                      : ""}
                  </span>
                </p>
                <div
                  class="img overflow-hidden"
                  style={{ cursor: "pointer", borderRadius: "5px" }}
                  onClick={() => gotoSingleCar(item, key)}
                >
                  {type === "video" ? (
                    <video
                      controls={false}
                      className="rounded-2"
                      height={400}
                      style={{
                        objectFit: "cover",
                        marginBottom: "-7px",
                        width: "100%",
                      }}
                    >
                      <source
                        src={firstImage || "assets/images/notFound.png"}
                      />
                    </video>
                  ) : (
                    <img
                      src={firstImage || "assets/images/notFound.png"}
                      alt="blog1"
                      className={`small-icon`}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  )}
                </div>
                <span className="d-flex justify-content-between my-2">
                  <h4
                    onClick={() => gotoSingleCar(item, key)}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      cursor: "pointer",
                      position: "unset",
                      paddingBottom: "5px",
                    }}
                  >
                    {t(item?.properties?.basicDetails?.make?.[lang]) +
                      " " +
                      t(item?.properties?.basicDetails?.model?.[lang])}
                  </h4>
                  <span
                    className="px-2 owl-card-heart position-relative"
                    style={{ cursor: "pointer", zIndex: 99, height: "20px" }}
                    onClick={() => toggleSaveCar(item?._id)}
                  >
                    {savedLoading && beingSaved === item?._id ? (
                      <Spinner
                        color="secondary"
                        size="sm"
                        type="grow"
                        className="position-absolute"
                        style={{
                          top: "2px",
                          right: lang === "en" ? "10px" : "10px",
                        }}
                      >
                        Loading...
                      </Spinner>
                    ) : (
                      <img
                        height={20}
                        style={{ width: "unset" }}
                        src={`assets/images/${
                          listOfSaved?.includes(item?._id)
                            ? "heart_fill.png"
                            : "heart.webp"
                        }`}
                        alt="img"
                      />
                    )}
                  </span>
                </span>
                {/* <span className="fw-bold text-light bg-danger float-end px-1 rounded-1">
                  {item?.brandNew ? "Brand New" : ""}
                </span> */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoSingleCar(item, key)}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      height: "45px",
                    }}
                  >
                    {tagText}
                  </p>
                  {/* <span class="title match d-sm-block d-none" data-mh="title">
                    {t(item?.properties?.specifications?.fuelType?.[lang])}
                  </span> */}

                  <div className="d-flex justify-content-between">
                    {/* <h3>${item?.adTypeName?.['en'] === 'Sell' ? item?.entity?.properties?.price : item?.entity?.properties?.price?.daily.concat('/Day')}</h3> */}
                    <h3 className="cardSmall" style={{ paddingBottom: "5px" }}>
                      {getPrice(item)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
export default OwlItemCars;
