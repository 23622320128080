import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  formatTime24to12,
  openAddressOnMap,
  removeAmPm,
  renderPhoneNumber,
  timeData12,
  timeData24,
} from "../../../utils/constants";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useSelector } from "react-redux";
import { initiateChat, userActivity } from "../../../Reducer/api";
import { Tooltip } from "react-tooltip";
import { paths } from "../../../utils/paths";

export default function BrandInfo({ responce, dealerCars, ...props }) {
  const { t, i18n } = useTranslation();
  const udata = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  useEffect(() => {
    const adIdToChat = localStorage.getItem("adIdToChat");
    if (adIdToChat) {
      const tmp = localStorage.getItem("chatInitiated");
      if (tmp == "true") {
        localStorage.setItem("chatInitiated", "false");
        localStorage.setItem("adIdToChat", "");
        handleInitiateChat(adIdToChat);
      }
    }
  }, [localStorage]);

  const singleDealer = props?.singleDealer;
  const [readMore, setReadMore] = useState(false);

  const goToViewAllCars = (id) => {
    navigate(`${paths.dealerCarView}?ownerId=${id}`);
  };

  const formatValue = (time) => {
    // const time = timeData12[timeData24.indexOf(time)]
    const a = time.substring(time.length - 2);
    let res = "";
    if (a.toLowerCase() == "am") {
      res = t("AM");
    } else if (a.toLowerCase() == "pm") {
      res = t("PM");
    } else {
      return time;
    }
    if (!res) {
      return time;
    }
    return time.substring(0, time.length - 2).concat(" " + res);
  };

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const handleInitiateChat = (id) => {
    if (chatNow.loading) return;
    if (!udata.token) {
      navigate(paths.login, {
        state: {
          backPage: location.pathname + location.search,
          request: "chat",
          adIdToChat: id,
        },
      });
      return;
    }
    setChatNow({ ...chatNow, adId: id, loading: true });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate(paths.messages, {
            state: { chatId: res.chatId },
          });
        }
        setChatNow({ ...chatNow, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const phoneClick = () => {
    userActivity({ dealerId: responce?._id, type: "phoneView" });
  };

  return (
    <>
      {responce ? (
        <div class="container">
          <h6 class="back">
            <a onClick={() => navigate(-1)}>
              <img src="../assets/images/backbt.png" /> {t("Go back")}
            </a>
          </h6>
          <div class="row">
            <div class="col-lg-6 col-xxl-7">
              <div class="singlepage_left">
                <img
                  style={{
                    objectFit: "contain",
                    border: "0.2px solid #ebebeb",
                    borderRadius: "9px",
                    height: "400px",
                  }}
                  class={`w-100 mb-3`}
                  src={
                    responce?.coverPicture
                      ? responce?.coverPicture
                      : "../assets/images/notFound.png"
                  }
                  alt="bg"
                />
                <h3 class="mt-5 mb-3 fw-bold">{t("About us")}</h3>
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: readMore ? 100 : 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    // minWidth: "350px",
                  }}
                >
                  {responce?.additionalInfo?.about?.[lang]
                    ? responce?.additionalInfo?.about?.[lang]
                    : "--"}
                </p>
                {responce?.additionalInfo?.about?.[lang]?.length > 50 && (
                  <a
                    className="mb-3"
                    onClick={() => {
                      setReadMore(!readMore);
                    }}
                  >
                    {readMore ? t("See less") : t("See more")}
                  </a>
                )}

                {/* <p>{responce.aboutus[i18n.language]}</p> */}
              </div>
            </div>
            <div class="col-lg-6 col-xxl-5">
              <div class="singlepage_right second">
                <div className="d-flex justify-content-start align-items-center mb-5">
                  <h3 className="">{responce?.dealerShipName}</h3>
                  {responce?.kyc?.name?.en.includes("verified") && (
                    <span
                      data-tooltip-id="verified-tip"
                      data-tooltip-content={t("Verified by Ako Sayara")}
                      id="verified-tip"
                      className=""
                      style={{
                        margin: "-5px 15px 0px 15px",
                      }}
                    >
                      <img
                        src="assets/images/verified.svg"
                        height={30}
                        width={30}
                      />
                    </span>
                  )}
                </div>
                {/* <h4>
                  <img src="../assets/images/bld.png" alt="img" />
                  {t("Business")}
                </h4> */}
                <div class="row btns">
                  <div class="col-12 mb-3">
                    <a
                      onClick={() => goToViewAllCars(responce?._id)}
                      class="btn call w-100"
                    >
                      {t("View all cars")}
                    </a>
                  </div>
                  {console.log(responce?._id === udata.auth.id)}
                  {responce?._id === udata.auth.id ? (
                    <></>
                  ) : (
                    <>
                      {renderPhoneNumber(responce, ({ phone }) => (
                        <div className="col-6">
                          <a
                            href={`tel:${phone}`}
                            class="btn w-100"
                            onClick={(e) => phoneClick()}
                          >
                            <img src="../assets/images/call-K2.png" alt="img" />
                            {t("Call dealer")}
                          </a>
                        </div>
                      ))}
                      <div className={"col"}>
                        <UncontrolledDropdown>
                          <DropdownToggle className="bg-light w-100">
                            {chatNow?.loading ? (
                              t("Please wait...")
                            ) : (
                              <>
                                <img
                                  src="../assets/images/message-K.png"
                                  alt="img"
                                />{" "}
                                {t("Message dealer")}
                              </>
                            )}
                          </DropdownToggle>
                          <DropdownMenu
                            className={`w-100 mt-2 text-${
                              lang === "en" ? "start" : "end"
                            }`}
                          >
                            <DropdownItem header>
                              {t("Brand Cars")}
                            </DropdownItem>
                            <DropdownItem divider />
                            {dealerCars?.map((item, index) => {
                              return (
                                <DropdownItem
                                  onClick={() => handleInitiateChat(item?._id)}
                                >
                                  <span
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  >
                                    {`${t(
                                      item?.properties?.basicDetails?.make?.en
                                    )} ${t(
                                      item?.properties?.basicDetails?.model?.en
                                    )} ${t(
                                      item?.properties?.basicDetails?.makeYear
                                    )}`}
                                  </span>
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </>
                  )}
                </div>

                {(responce?.address && responce?.address !== "N/A") ||
                responce?.additionalInfo?.website ||
                (responce?.phoneNumber && responce?.phoneNumber !== "N/A") ? (
                  <h5>{t("Get In Touch")}</h5>
                ) : (
                  <></>
                )}

                <div class="row">
                  {responce?.address && responce?.address !== "N/A" && (
                    <div
                      class="col-sm-4 "
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        openAddressOnMap(
                          responce?.address ? responce?.address : null
                        )
                      }
                    >
                      <h6>{t("Address")}</h6>
                      <p className="hoverUnderline text-primary">
                        {responce?.address ? responce?.address : "--"}
                      </p>
                    </div>
                  )}
                  {responce?.additionalInfo?.website && (
                    <div class="col-sm-4">
                      <h6>{t("Website")}</h6>
                      <a
                        href={`${responce?.additionalInfo?.website}`}
                        target="_blank"
                      >
                        {responce?.additionalInfo?.website
                          ? t("Visit website")
                          : "--"}
                      </a>
                    </div>
                  )}
                  {renderPhoneNumber(responce, ({ phone }) => (
                    <div class="col-sm-4">
                      <h6>{t("Phone")}</h6>
                      <a href={`tel:${phone}`}>
                        <span role="" onClick={(e) => phoneClick()}>
                          {`${lang === "en" ? "+" : ""}${phone}${
                            lang === "en" ? "" : "+"
                          }`}
                        </span>
                      </a>
                    </div>
                  ))}
                </div>

                {responce?.additionalInfo?.dealerWorkingHours && (
                  <>
                    <h6 class="mb-3">{t("Working hours")}</h6>
                    <ul class="d-flex flex-column">
                      {responce?.additionalInfo?.dealerWorkingHours
                        ? responce?.additionalInfo?.dealerWorkingHours?.map(
                            (item, key) => {
                              return (
                                <div
                                  key={key}
                                  className="d-flex justify-content-between"
                                  style={{
                                    paddingLeft: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <span>{t(item?.day)}</span>
                                  <span
                                    style={{
                                      color: !item?.active
                                        ? "#e44e4e"
                                        : "black",
                                    }}
                                  >
                                    {!item?.active ? (
                                      t("Closed")
                                    ) : (
                                      <span
                                        dir={
                                          i18n.language === "Kurdish"
                                            ? "rtl"
                                            : ""
                                        }
                                      >
                                        <span
                                          dir={
                                            i18n.language === "Kurdish"
                                              ? "rtl"
                                              : ""
                                          }
                                        >
                                          {removeAmPm(item?.startTime)}
                                        </span>
                                        {" - "}
                                        <span
                                          dir={
                                            i18n.language === "Kurdish"
                                              ? "rtl"
                                              : ""
                                          }
                                        >
                                          {removeAmPm(item?.endTime)}
                                        </span>
                                      </span>
                                    )}
                                  </span>
                                </div>
                              );
                            }
                          )
                        : "--"}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
          <Tooltip
            id="verified-tip"
            variant="info"
            style={{ backgroundColor: "#29b2fe" }}
          />
        </div>
      ) : (
        "Loading....."
      )}
    </>
  );
}
