import React, { useEffect, useRef, useState } from "react";
import ListItem from "../../Tools/ListItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FirstForm from "./FirstForm";
import SecondForm from "./SecondForm";
import { fromDetail } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import { getModels } from "../../../Reducer/api";
import { bannerBuy } from "../../../utils/paths";
import {
  getAllFiltersData,
  getSelectedFilters,
} from "../../../utils/constants";

export default function BannerSection({ carTypes, page }) {
  const { t } = useTranslation();
  const detail = useSelector((state) => state.fromsdata.fromsdata);
  const loading = useSelector((state) => state.fromsdata.loading);
  const error = useSelector((state) => state.fromsdata.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fromDetail());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    bodyType: [],
    price: {
      min: "",
      max: "",
    },
  };

  const [models, setModels] = useState([]);
  const [filterData, setFilterData] = useState(defaultValues);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const filtersRef = useRef(filterData);

  // useEffect(() => {
  //   getMake({ isLive: true, limit: 200, ownerId: "", page: page, filters: filterData }).then(
  //     (res) => {
  //       // res = res?.map((v) => ({ label: v, value: v }));
  //       let data = {
  //         name: t("Any"),
  //         value: "Any",
  //       };
  //       setMakes([data, ...res?.makes]);
  //     }
  //   );
  // }, [filterData]);

  useEffect(() => {
    setModels([]);
    if (filterData.make) {
      getModels({
        makeId: filterData.make,
        live: true,
        ownerId: "",
        page: page,
      }).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    }

    // else {
    //   getAllModels().then((res) => {
    //     res = res?.map((v) => ({ label: v, value: v }));
    //     setModels(res);
    //   });
    // }
  }, [filterData.make]);

  useEffect(() => {
    console.log(filterData);
  }, [filterData]);

  const resetFilters = () => {
    setFilterData(defaultValues);
    setModels([]);
  };

  const [adFiltersList, setAdFiltersList] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const getFilters = async () => {
    setFiltersLoading(true)
    const apiCall = await getAllFiltersData(
      page,
      "",
      filterData,
      getSelectedFilters(
        selectedFilters,
        setSelectedFilters,
        filterData,
        filtersRef
      )
    );

    setAdFiltersList(apiCall);
    
    setFiltersLoading(false)
  };
  useEffect(() => {
    getFilters();
  }, [filterData]);

  return (
    <section
      class="banner"
      style={{
        backgroundImage: `url(${
          bannerBuy ? bannerBuy : "/assets/images/bannerhome.jpg"
        })`,
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="setform" style={{ overflow: "unset" }}>
              <ul
                class="nav nav-tabs d-flex usedNewTabs"
                id="myTab"
                role="tablist"
              >
                <ListItem
                  key={1}
                  style={"nav-item"}
                  innerhtmlType={"modalButton"}
                  listClick={(e) => {
                    setFilterData({ ...filterData, brandNew: false });
                    setSelectedFilters([]);
                  }}
                  innerData={{
                    class: "nav-link active",
                    id: "tab1-tab",
                    toggle: "tab",
                    target: "#tab1",
                    type: "button",
                    role: "tab",
                    controls: "tab1",
                    selected: "true",
                    title: t("Used cars"),
                  }}
                />
                <ListItem
                  key={2}
                  style={"nav-item"}
                  innerhtmlType={"modalButton"}
                  listClick={(e) => {
                    setFilterData({ ...filterData, brandNew: true });
                    setSelectedFilters([]);
                  }}
                  innerData={{
                    class: "nav-link",
                    id: "tab2-tab",
                    toggle: "tab",
                    target: "#tab2",
                    type: "button",
                    role: "tab",
                    controls: "tab2",
                    selected: "false",
                    title: t("New cars"),
                  }}
                />
              </ul>
              <div class="tab-content" id="myTabContent">
                <FirstForm
                  data={detail}
                  carTypes={carTypes}
                  models={models}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  adFiltersList={adFiltersList}
                  loading={filtersLoading}
                />
                <SecondForm
                  data={detail}
                  carTypes={carTypes}
                  models={models}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  adFiltersList={adFiltersList}
                  loading={filtersLoading}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2 style={{ textShadow: "1px 1px 5px gray" }}>
              {t("Find your perfect car")}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
