import React from "react";
import ListItem from "../../Tools/ListItem";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { exPh, openAddressOnMap } from "../../../utils/constants";
import { userActivity } from "../../../Reducer/api";
import { paths } from "../../../utils/paths";
import PopoverTool from "../../Tools/PopoverTool";
import CompareComponent from "./compareComponent";

export default function SinglePageRight({
  classNames,
  page,
  data,
  radioState,
  handleChange,
  getPrice,
  lang,
  params,
  handleInitiateChat,
  chatNow,
  chatNowPop,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const userType = data?.data?.[0]?.owner?.userType;

  const udata = useSelector((state) => state.auth);
  console.log(radioState);

  const getRadioValue = () => {
    return (
      <ul
        class="my-3 d-flex"
        style={{ direction: lang === "en" ? null : "rtl", height: "30px" }}
      >
        <>
          <label
            htmlFor="dailyRadio"
            className={`my-radio m${lang === "en" ? "e" : "s"}-3`}
            style={{ cursor: "pointer" }}
          >
            <div className="my-radio-icon">
              <div
                className={`my-radio-icon-inner ${
                  radioState === "daily" && "my-radio-active"
                }`}
              ></div>
            </div>
            <input
              type="checkbox"
              id="dailyRadio"
              name="quality"
              checked={radioState === "daily"}
              onChange={handleChange}
              class="quality delivery"
              value="daily"
              hidden
            />
            <span className={`m${lang === "en" ? "s" : "e"}-1`}>
              {t("Daily")}
            </span>
          </label>
          <label
            htmlFor="weeklyRadio"
            className={`my-radio m${lang === "en" ? "e" : "s"}-3`}
            style={{ cursor: "pointer" }}
          >
            <div className="my-radio-icon">
              <div
                className={`my-radio-icon-inner ${
                  radioState === "weekly" && "my-radio-active"
                }`}
              ></div>
            </div>
            <input
              type="checkbox"
              id="weeklyRadio"
              name="quality"
              checked={radioState === "weekly"}
              onChange={handleChange}
              class="quality delivery"
              value="weekly"
              hidden
            />
            <span className={`m${lang === "en" ? "s" : "e"}-1`}>
              {t("Weekly")}
            </span>
          </label>
          <label
            htmlFor="monthlyRadio"
            className={`my-radio m${lang === "en" ? "e" : "s"}-3`}
            style={{ cursor: "pointer" }}
          >
            <div className="my-radio-icon">
              <div
                className={`my-radio-icon-inner ${
                  radioState === "monthly" && "my-radio-active"
                }`}
              ></div>
            </div>
            <input
              type="checkbox"
              id="monthlyRadio"
              name="quality"
              checked={radioState === "monthly"}
              onChange={handleChange}
              class="quality delivery"
              value="monthly"
              hidden
            />
            <span className={`m${lang === "en" ? "s" : "e"}-1`}>
              {t("Monthly")}
            </span>
          </label>
        </>
      </ul>
    );
  };

  const getPhNum = (ph) => {
    if (exPh.includes(ph)) {
      return exPh[2];
    } else {
      return ph;
    }
  };

  return (
    <div className={classNames}>
      <h5 className="d-flex justify-content-end align-items-center">
        <CompareComponent
          data={data?.data?.[0]}
          className={"d-flex justify-content-between d-lg-none w-100"}
        />
      </h5>
      <h1 className="fw-semibold small-title">
        {t(data?.data?.[0]?.properties?.basicDetails?.make?.[lang]) +
          " " +
          t(data?.data?.[0]?.properties?.basicDetails?.model?.[lang])}
      </h1>
      <h1 className="fw-bolder fs-3 mt-3">
        {data?.data?.[0]?.properties?.basicDetails?.makeYear}
      </h1>
      {page === "rental" ? getRadioValue() : null}
      <h2>{getPrice()}</h2>
      {data?.data?.[0]?.owner?._id !== udata.auth.id && (
        <div class="btns row">
          <div className="col-6">
            <ListItem
              style={"btn call w-100"}
              innerhtmlType={"html"}
              value={
                <NavLink
                  onClick={(e) => {
                    // userActivity(data?.data?.[0]?._id, "phoneView");
                    userActivity({
                      dealerId: data?.data?.[0]?.owner?._id,
                      type: "phoneView",
                      adId: data?.data?.[0]?._id,
                    });
                  }}
                  to={`tel:${getPhNum(
                    data?.data?.[0]?.owner?.additionalInfo?.phoneNumber1 ||
                      data?.data?.[0]?.owner?.phoneNumber
                  )}`}
                >
                  <img src="../assets/images/call-K.png" alt="img" />
                  {t("Call Seller")}
                </NavLink>
              }
            />
          </div>
          <div className="col-6">
            <PopoverTool
              id={`PopoverTool${classNames.split("-").pop()}${
                data?.data?.[0]?._id
              }`}
              open={chatNowPop}
              className={classNames}
            >
              <div>
                {t(
                  "Please complete your profile and verify mobile number to start chatting"
                )}
              </div>
            </PopoverTool>
            <ListItem
              style={"btn w-100"}
              innerhtmlType={"html"}
              value={
                <a
                  id={`PopoverTool${classNames.split("-").pop()}${
                    data?.data?.[0]?._id
                  }`}
                  onClick={() => handleInitiateChat(data?.data?.[0]?._id)}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    cursor: chatNow.loading ? "default" : "pointer",
                  }}
                >
                  <img src="../assets/images/message-K.png" alt="icon" />{" "}
                  {chatNow.loading && chatNow.adId === data?.data?.[0]?._id
                    ? t("Please wait...")
                    : t("Chat With Seller")}
                </a>
              }
            />
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: "#F0F0F0",
          width: "fit-content",
          height: "70px",
          marginLeft: "0px",
          margin: "32px 0px 30px 0px",
          cursor: ["dealer", "brand"].includes(userType)
            ? "pointer"
            : "default",
        }}
        className="rounded-2 d-flex justify-content-center"
        onClick={() => {
          if (userType === "dealer") {
            navigate(
              `${paths.searchResultPage}?id=${data?.data?.[0]?.owner?._id}`
            );
          } else if (userType === "brand") {
            navigate(`${paths.singleBrand}?id=${data?.data?.[0]?.owner?._id}`);
          }
        }}
      >
        <div className="d-flex justify-content-center align-items-center mx-3">
          <img
            src={
              data?.data?.[0]?.owner?.coverPicture ||
              data?.data?.[0]?.owner?.profilePicture ||
              "../assets/images/ap2.png"
            }
            style={{
              width: "50px",
              height: "50px",
              objectFit: "contain",
              objectPosition: "center",
            }}
            className="rounded-circle p-1 bg-light"
          />
        </div>
        <div
          className={`d-flex align-items-center m${
            lang === "en" ? "e" : "s"
          }-3`}
        >
          <span
            className={`fw-semibold fs-5`}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              // overflow: "hidden",
            }}
          >
            {userType === "brand"
              ? t(
                  (data?.data?.[0]?.owner?.dealerShipName !== "N/A" &&
                    data?.data?.[0]?.owner?.dealerShipName) ||
                    data?.data?.[0]?.owner?.fullName
                )
              : (data?.data?.[0]?.owner?.dealerShipName !== "N/A" &&
                  data?.data?.[0]?.owner?.dealerShipName) ||
                data?.data?.[0]?.owner?.fullName}
          </span>
        </div>
      </div>
      {!["dealer", "brand"].includes(userType) ? (
        <ul style={{ listStyleType: "none", width: "100%" }} className="d-flex">
          <ListItem
            innerhtmlType={"html"}
            inlineStyle={{ paddingLeft: "0px", paddingRight: "0px" }}
            value={
              <a
                style={{ cursor: "pointer" }}
                // href={
                //   data?.data?.[0]?.city ? t(data?.data?.[0]?.city) : "Baghdad"
                // }
                target="_blank"
                className="-end"
              >
                <a className="hoverUnderline d-flex align-items-center">
                  <span className="d-flex align-items-center">
                    <img
                      style={{ position: "unset" }}
                      src={"../assets/images/Vector-4.png"}
                      alt="img"
                    />
                  </span>
                  <span className="mx-2">{data?.data?.[0]?.owner?.city}</span>
                </a>
                {/* <span className="d-flex justify-content-start align-items-center">
                  <img src={"../assets/images/location.png"} alt="img" />
                  <span>
                    {data?.data?.[0]?.city
                      ? t(data?.data?.[0]?.city)
                      : "Baghdad"}
                  </span>
                </span> */}
              </a>
            }
          />
        </ul>
      ) : (
        <ul className="d-flex justify-content-between">
          {data?.data?.[0]?.owner?.additionalInfo?.website && (
            <ListItem
              innerhtmlType={"html"}
              inlineStyle={{ paddingLeft: "0px", paddingRight: "0px" }}
              value={
                <a
                  style={{ cursor: "pointer" }}
                  href={data?.data?.[0]?.owner?.additionalInfo?.website}
                  target="_blank"
                >
                  <span className="d-flex align-items-center">
                    <img
                      src={"../assets/images/visitWebsite.png"}
                      alt="img"
                      style={{
                        top: "-45px",
                        position: "unset",
                        margin: "unset",
                      }}
                    />

                    <span className="mx-2">{t("Visit website")}</span>
                  </span>
                </a>
              }
            />
          )}
          {data?.data?.[0]?.owner?.address && (
            <ListItem
              innerhtmlType={"html"}
              inlineStyle={{
                float: "unset",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
              listClick={() =>
                openAddressOnMap(data?.data?.[0]?.owner?.address)
              }
              value={
                <a className="hoverUnderline d-flex">
                  <span>
                    <img
                      style={{ marginTop: "3px", position: "unset" }}
                      src={"../assets/images/Vector-4.png"}
                      alt="img"
                    />
                  </span>
                  <span className="mx-2">
                    {data?.data?.[0]?.owner?.address}
                  </span>
                </a>
              }
            />
          )}
        </ul>
      )}
    </div>
  );
}
