import Basicsfilters from "./Basicsfilters";
import DealersList from "./DealersList";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { title } from "../../../data/content";
import { dealerList } from "../../../Reducer/dealerFilterSilce";
import ShortBy from "./ShortBy";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import filterData from "../../../data/dealerFilterSlice.json";
import { itemLimit } from "../../../utils/constants";
import { fetchBrandList, getDealerCitites } from "../../../Reducer/api";
import { useDidMountEffect } from "../../../utils/hooks";
export default function BrandSearch() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [sortVal, setSortVal] = useState({
    sort: "",
    order: "",
  });
  const auth = useSelector((state) => state.auth.auth);
  let dataId = { id: auth && auth.id ? auth.id : "" };
  // const [currentPage, setCurrentPage] = useState(0);
  const [dealerCities, setDealerCities] = useState(null);
  const [filters, setFilters] = useState({ city: "", name: "" });
  const filtersRef = useRef(filters);

  const currentPage = parseInt(params.get("page")) || 1;
  const setCurrentPage = (n) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set("page", n);
    navigate(`${location.pathname}?${currentParams.toString()}`, {
      replace: true,
    });
  };

  //pagination previous button
  const previous = () => {
    let page = currentPage;
    if (page > 1) setCurrentPage(page - 1);
  };

  //pagination next button
  const next = () => {
    let page = currentPage;
    if (page < brands?.totalPages) setCurrentPage(page + 1);
  };

  useEffect(() => {
    setLoading(true);
    fetchBrandList({
      dataId: dataId,
      page: currentPage,
      itemLimit: itemLimit,
      setLoading: setLoading,
      filters: filters,
      sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
    })
      .then((res) => {
        if (res?.status) {
          setBrands(res?.data);
        } else {
          setBrands([]);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [
    filters.name.length,
    filters.city,
    filters.distance,
    sortVal,
    currentPage,
  ]);

  useEffect(() => {
    getDealerCitites({ userType: "brand" }).then((res) => {
      if (res.status) {
        setDealerCities(res?.data);
      }
    });
  }, []);

  useDidMountEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(filtersRef.current)) {
      setCurrentPage(1);
    }
  }, [filters]);

  return (
    <section class="advance_search car_search_results dealer">
      <div class="container">
        <h6 className="back d-flex justify-content-between align-items-center mb-3">
          <a onClick={() => navigate(-1)} className="dealer-search-title">
            <img src="../assets/images/backbt.png" /> {t("Go back")}
          </a>
          <div class="advance_search_in mb-0">
            <div
              className="text-end w-100"
              style={{ fontSize: "26px", fontWeight: 500 }}
            >
              {t("Search brand")}
            </div>
          </div>
        </h6>
        <div class="advance_search_in">
          <ShortBy previous={previous} next={next} setSortVal={setSortVal} />
          <Basicsfilters
            city={filterData.data.filter}
            dealerCities={dealerCities}
            distance={filterData.data.Distancefilter}
            filters={filters}
            setFilters={setFilters}
          />
          {loading ? (
            <div
              class="d-flex justify-content-center"
              style={{ minHeight: "100vh", marginTop: "25vh" }}
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <DealersList
              responce={brands?.docs || []}
              pageCount={brands?.totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </section>
  );
}
