import { t } from "i18next";
import React from "react";
import SelectForm from "../../SelectForm";
import { useSelector } from "react-redux";
import FormInput from "../../FormInput";

export default function Specifications({ data, register, errors, watch }) {
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  return (
    <div class="other_div other_div2">
      <div className="row d-flex justify-content-between">
        <div className="col-7">
          <h3>{t("Specifications")}</h3>
        </div>
        {/* <div className={`col-5 mt-1 d-flex justify-content-end`}>
              <div class="form-check form-switch">
                <input
                  class={`form-check-input ${lang2 !== "en" ? "ms-2" : ""}`}
                  onChange={(e) => setBrandNew(e.target.checked)}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
              <label
                style={{ marginTop: "unset" }}
                class="form-check-label"
                for="flexSwitchCheckDefault"
              >
                {t("Brand new")}
              </label>
            </div> */}
      </div>
      <div class="row">
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Paint")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={data?.properties?.specifications?.paint?.[lang2]}
              event={register}
              name={"paint"}
              values={upload_list.paint ? upload_list.paint : []}
            />
            {errors.paint && <p>{errors.paint.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Kilometers")}*</label>
            <FormInput
              style={"form-control"}
              intype={"text"}
              value={data?.properties?.specifications?.km}
              event={register}
              placeholder={t("Select Kilometres")}
              name={"kilometers"}
            />
            {errors.kilometers && <p>{errors.kilometers.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Transmission")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={
                data?.properties?.specifications?.gearbox?.[lang2]
              }
              event={register}
              name={"transmission"}
              values={upload_list.transmission ? upload_list.transmission : []}
            />
            {errors.transmission && <p>{errors.transmission.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Fuel Type")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={
                data?.properties?.specifications?.fuelType?.[lang2]
              }
              event={register}
              name={"fuel"}
              values={upload_list.fuel ? upload_list.fuel : []}
            />
            {errors.fuel && <p>{errors.fuel.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label> {t("Body Type")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={
                data?.properties?.specifications?.bodyType?.[lang2]
              }
              event={register}
              name={"body"}
              values={upload_list.body ? upload_list.body : []}
            />
            {errors.body && <p>{errors.body.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          {watch("fuel") === t("Electric") ? (
            <div class="form-group">
              <label>{t("Battery size (kWh)")}</label>
              <FormInput
                style={"form-control"}
                intype={"text"}
                value={data?.properties?.specifications?.batterySize}
                event={register}
                placeholder={t("Enter battery size")}
                name={"batterySize"}
              />
              {errors.batterySize && <p>{errors.batterySize.message}</p>}
            </div>
          ) : (
            <div class="form-group">
              <label>{t("Engine Size")}</label>
              <SelectForm
                style={"form-control"}
                selectedOption={
                  upload_list?.size?.filter(
                    (v) =>
                      v.value == data?.properties?.specifications?.engineSize
                  )?.[0]?.name?.[lang2]
                  // data?.properties?.specifications?.engineSize?.[lang2]
                }
                event={register}
                name={"size"}
                values={upload_list.size ? upload_list.size : []}
              />
              {errors.size && <p>{errors.size.message}</p>}
            </div>
          )}
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Engine Power (bhb)")}</label>
            {/* <SelectForm
              style={"form-control"}
              selectedOption={
                upload_list?.power?.filter(
                  (v) =>
                    v.value == data?.properties?.specifications?.enginePower
                )?.[0]?.name?.[lang2]
                // data?.properties?.specifications?.enginePower?.[lang2]
              }
              event={register}
              name={"power"}
              values={upload_list.power ? upload_list.power : []}
            /> */}
            <FormInput
              style={"form-control"}
              intype={"text"}
              value={data?.properties?.specifications?.power}
              event={register}
              placeholder={t("Enter engine power")}
              name={"power"}
            />
            {errors.power && <p>{errors.power.message}</p>}
          </div>
        </div>
        {watch("fuel") === t("Electric") ? (
          <></>
        ) : (
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label>{t("Cylinders")}</label>
              <SelectForm
                style={"form-control"}
                selectedOption={data?.properties?.specifications?.cylinders}
                event={register}
                name={"cylinders"}
                values={upload_list.cylinder ? upload_list.cylinder : []}
              />
              {errors.cylinders && <p>{errors.cylinders.message}</p>}
            </div>
          </div>
        )}
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Number of Doors")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={data?.properties?.specifications?.doors}
              event={register}
              name={"doors"}
              values={upload_list.doors ? upload_list.doors : []}
            />
            {errors.doors && <p>{errors.doors.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Number of Seats")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={data?.properties?.specifications?.seats}
              event={register}
              name={"seats"}
              values={upload_list.seats ? upload_list.seats : []}
            />
            {errors.seats && <p>{errors.seats.message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
